import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'

import Div from '../elements/Div'
import H4 from '../elements/H4'
import P from '../elements/P'
import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomDiv from '../lib/generate/image'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ div }) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '16rem',
      height: '100%',
      maxHeight: '100vh',
      backgroundColor: div.parentBg
    }}
  >
    <img
      src={div.backgroundImage}
      css={{
        filter: `blur(${div.blur}px)`,
        opacity: div.opacity / 100,
        backgroundSize: div.backgroundSize,
        mixBlendMode: div.mixBlendMode,
        maxHeight: '96%',
        display: 'block',
        alignItems: div.alignItems,
        paddingRight: div.paddingRight + 'px',
        paddingLeft: div.paddingLeft + 'px',
        paddingTop: div.paddingTop + 'px',
        paddingBottom: div.paddingBottom + 'px',
        boxShadow: `${div.boxShadowInset ? 'inset' : ''} ${
          div.boxShadowOffsetX
        }px ${div.boxShadowOffsetY}px ${div.boxShadowSpread}px ${
          div.boxShadowBlur
        }px ${div.boxShadowColor}`,
        backgroundColor: div.bg,
        width: div.width + '%',
        borderTopStyle: div.borderTopStyle,
        borderTopWidth: div.borderTopWidth + 'px',
        borderTopColor: div.borderTopColor,
        borderBottomStyle: div.borderBottomStyle,
        borderBottomWidth: div.borderBottomWidth + 'px',
        borderBottomColor: div.borderBottomColor,
        borderLeftStyle: div.borderLeftStyle,
        borderLeftWidth: div.borderLeftWidth + 'px',
        borderLeftColor: div.borderLeftColor,
        borderRightStyle: div.borderRightStyle,
        borderRightWidth: div.borderRightWidth + 'px',
        borderRightColor: div.borderRightColor,
        borderRadius: `${div.topRightRadius}% ${div.topLeftRadius}% ${div.bottomRightRadius}% ${div.bottomLeftRadius}% / ${div.mirrorTopRightRadius}% ${div.mirrorTopLeftRadius}% ${div.mirrorBottomRightRadius}% ${div.mirrorBottomLeftRadius}%`
      }}
      style={{
        borderImageSource: `url(${div.borderImageSource})`,
        borderImageOutset: div.borderImageOutset + 'px',
        borderImageRepeat: div.borderImageRepeat,
        borderImageSlice: div.borderImageSlice,
        borderImageWidth: div.borderImageWidth + 'px'
      }}
    />
  </div>
)

const GridItem = ({ div, ...props }) => (
  <div
    css={{
      width: '25%',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '16px',
      height: '19vh'
    }}
    {...props}
  >
    <a
      css={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <div></div>
    </a>
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(16)
      .fill(0)
      .map((_, i) => {
        const baseDiv = randomDiv()
        const divWithPins = { ...baseDiv, ...pins }
        const div = {
          ...divWithPins,
          backgroundImage: 'https://mrmrs.cc/photos/u/001.jpg' //randomAvatar.toBackgroundImage(gradientWithPins)
        }

        return <GridItem key={i} onClick={() => onSelect(div)} div={div} />
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Image</SidebarHeading>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.backgroundImage}
          onClick={() => onPin('text', value.backgroundImage)}
        />
        <Label>Image</Label>
        <Input
          width={1}
          value={value.backgroundImage}
          type="text"
          onChange={changeValue('backgroundImage')}
        />
      </Flex>
      <Color
        label="Canvas Bg"
        value={value.parentBg}
        onChange={changeValue('parentBg')}
        active={pins.parentBg}
        onLock={() => onPin('parentBg', value.parentBg)}
        width={128}
      />
      <Color
        label="Color"
        value={value.color}
        onChange={changeValue('color')}
        active={pins.color}
        onLock={() => onPin('color', value.color)}
        width={128}
      />
      <Color
        label="Background"
        value={value.bg}
        onChange={changeValue('bg')}
        active={pins.bg}
        onLock={() => onPin('bg', value.bg)}
        width={128}
      />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.blur}
          onClick={() => onPin('blur', value.blur)}
        />
        <Label>Blur</Label>
        <Input
          min={0}
          max={200}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.blur)}
          type="range"
          onChange={changeValue('blur')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          blur={0}
          children={value.blur}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.mixBlendMode}
          onClick={() => onPin('mixBlendMode', value.mixBlendMode)}
        />
        <Label>Blend Mode</Label>
        <select
          value={value.mixBlendMode}
          onChange={changeValue('mixBlendMode')}
        >
          <option>normal</option>
          <option>multiply</option>
          <option>screen</option>
          <option>overlay</option>
          <option>darken</option>
          <option>lighten</option>
          <option>color-dodge</option>
          <option>color-burn</option>
          <option>hard-light</option>
          <option>soft-light</option>
          <option>difference</option>
          <option>exclusion</option>
          <option>hue</option>
          <option>saturation</option>
          <option>color</option>
          <option>luminosity</option>
        </select>
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.opacity}
          onClick={() => onPin('opacity', value.opacity)}
        />
        <Label>Opacity</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.opacity)}
          type="range"
          onChange={changeValue('opacity')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          opacity={0}
          children={value.opacity}
        />
      </Flex>
      <SectionBorder my={2} />
      <H5 fontSize={0} mb={2}>
        Box Shadow
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadowInset}
          onClick={() => onPin('boxShadowInset', value.boxShadowInset)}
        />
        <Label>Inset</Label>
        <Input
          mr={2}
          checked={value.boxShadowInset}
          type="checkbox"
          onChange={() => {
            onChange('boxShadowInset', !value.boxShadowInset)
          }}
        />
      </Flex>
      <Flex>
        <Lock
          bg="transparent"
          active={pins.boxShadowOffsetX}
          onClick={() => onPin('boxShadowOffsetX', value.boxShadowOffsetX)}
        />
        <Label>Offset X</Label>
        <Input
          min={-100}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.boxShadowOffsetX)}
          type="range"
          onChange={changeValue('boxShadowOffsetX')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.boxShadowOffsetX}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadowOffsetY}
          onClick={() => onPin('boxShadowOffsetY', value.boxShadowOffsetY)}
        />
        <Label>Offset Y</Label>
        <Input
          min={-100}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.boxShadowOffsetY)}
          type="range"
          onChange={changeValue('boxShadowOffsetY')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.boxShadowOffsetY}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadowBlur}
          onClick={() => onPin('boxShadowBlur', value.boxShadowBlur)}
        />
        <Label>Blur</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.boxShadowBlur)}
          type="range"
          onChange={changeValue('boxShadowBlur')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.boxShadowBlur}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.boxShadowSpread}
          onClick={() => onPin('boxShadowSpread', value.boxShadowSpread)}
        />
        <Label>Spread</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.boxShadowSpread)}
          type="range"
          onChange={changeValue('boxShadowSpread')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.boxShadowSpread}
        />
      </Flex>
      <Color
        value={value.boxShadowColor}
        onChange={changeValue('boxShadowColor')}
        active={pins.boxShadowColor}
        onLock={() => onPin('boxShadowColor', value.boxShadowColor)}
        width={128}
      />

      <SectionBorder my={3} />
      <H5 fontWeight={500} fontSize={0} mt={2} mb={2}>
        Border Top
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderTopWidth}
          onClick={() => onPin('borderTopWidth', value.borderTopWidth)}
        />
        <Label>Width</Label>
        <Input
          min={0}
          max={128}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.borderTopWidth)}
          type="range"
          onChange={changeValue('borderTopWidth')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.borderTopWidth}
        />
      </Flex>

      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderTopStyle}
          onClick={() => onPin('borderTopStyle', value.borderTopStyle)}
        />
        <Label>Style</Label>
        <select
          value={value.borderTopStyle}
          onChange={changeValue('borderTopStyle')}
        >
          <option>none</option>
          <option>solid</option>
          <option>dotted</option>
          <option>dashed</option>
          <option>double</option>
          <option>groove</option>
          <option>ridge</option>
          <option>inset</option>
          <option>outset</option>
        </select>
      </Flex>
      <Color
        label="Color"
        value={value.borderTopColor}
        onChange={changeValue('borderTopColor')}
        active={pins.borderTopColor}
        onLock={() => onPin('borderTopColor', value.borderTopColor)}
        width={128}
      />

      <H5 fontWeight={500} fontSize={0} mt={2} mb={2}>
        Border Right
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderRightWidth}
          onClick={() => onPin('borderRightWidth', value.borderRightWidth)}
        />
        <Label>Width</Label>
        <Input
          min={0}
          max={128}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.borderRightWidth)}
          type="range"
          onChange={changeValue('borderRightWidth')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.borderRightWidth}
        />
      </Flex>

      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderRightStyle}
          onClick={() => onPin('borderRightStyle', value.borderRightStyle)}
        />
        <Label>Style</Label>
        <select
          value={value.borderRightStyle}
          onChange={changeValue('borderRightStyle')}
        >
          <option>none</option>
          <option>solid</option>
          <option>dotted</option>
          <option>dashed</option>
          <option>double</option>
          <option>groove</option>
          <option>ridge</option>
          <option>inset</option>
          <option>outset</option>
        </select>
      </Flex>
      <Color
        label="Color"
        value={value.borderRightColor}
        onChange={changeValue('borderRightColor')}
        active={pins.borderRightColor}
        onLock={() => onPin('borderRightColor', value.borderRightColor)}
        width={128}
      />
      <H5 fontWeight={500} fontSize={0} mt={2} mb={2}>
        Border Bottom
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderBottomWidth}
          onClick={() => onPin('borderBottomWidth', value.borderBottomWidth)}
        />
        <Label>Width</Label>
        <Input
          min={0}
          max={128}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.borderBottomWidth)}
          type="range"
          onChange={changeValue('borderBottomWidth')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.borderBottomWidth}
        />
      </Flex>

      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderBottomStyle}
          onClick={() => onPin('borderBottomStyle', value.borderBottomStyle)}
        />
        <Label>Style</Label>
        <select
          value={value.borderBottomStyle}
          onChange={changeValue('borderBottomStyle')}
        >
          <option>none</option>
          <option>solid</option>
          <option>dotted</option>
          <option>dashed</option>
          <option>double</option>
          <option>groove</option>
          <option>ridge</option>
          <option>inset</option>
          <option>outset</option>
        </select>
      </Flex>
      <Color
        label="Color"
        value={value.borderBottomColor}
        onChange={changeValue('borderBottomColor')}
        active={pins.borderBottomColor}
        onLock={() => onPin('borderBottomColor', value.borderBottomColor)}
        width={128}
      />
      <H5 fontWeight={500} fontSize={0} mt={2} mb={2}>
        Border Left
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderLeftWidth}
          onClick={() => onPin('borderLeftWidth', value.borderLeftWidth)}
        />
        <Label>Width</Label>
        <Input
          min={0}
          max={128}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.borderLeftWidth)}
          type="range"
          onChange={changeValue('borderLeftWidth')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.borderLeftWidth}
        />
      </Flex>

      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderLeftStyle}
          onClick={() => onPin('borderLeftStyle', value.borderLeftStyle)}
        />
        <Label>Style</Label>
        <select
          value={value.borderLeftStyle}
          onChange={changeValue('borderLeftStyle')}
        >
          <option>none</option>
          <option>solid</option>
          <option>dotted</option>
          <option>dashed</option>
          <option>double</option>
          <option>groove</option>
          <option>ridge</option>
          <option>inset</option>
          <option>outset</option>
        </select>
      </Flex>
      <Color
        label="Color"
        value={value.borderLeftColor}
        onChange={changeValue('borderLeftColor')}
        active={pins.borderLeftColor}
        onLock={() => onPin('borderLeftColor', value.borderLeftColor)}
        width={128}
      />

      <SectionBorder my={2} />
      <h5>Border Image</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderImageSource}
          onClick={() => onPin('text', value.borderImageSource)}
        />
        <Label>Source</Label>
        <Input
          width={1}
          value={value.borderImageSource}
          type="text"
          onChange={changeValue('backgroundImageSource')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderImageWidth}
          onClick={() => onPin('borderImageWidth', value.borderImageWidth)}
        />
        <Label>Width</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.borderImageWidth)}
          type="range"
          onChange={changeValue('borderImageWidth')}
        />
        <Span
          borderImageWidth="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.borderImageWidth}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderImageOutset}
          onClick={() => onPin('borderImageOutset', value.borderImageOutset)}
        />
        <Label>Outset</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.borderImageOutset)}
          type="range"
          onChange={changeValue('borderImageOutset')}
        />
        <Span
          borderImageOutset="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.borderImageOutset}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.borderImageSlice}
          onClick={() => onPin('borderImageSlice', value.borderImageSlice)}
        />
        <Label>Slice</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.borderImageSlice)}
          type="range"
          onChange={changeValue('borderImageSlice')}
        />
        <Span
          borderImageSlice="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.borderImageSlice}
        />
      </Flex>
      <SectionBorder my={2} />
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.width}
          onClick={() => onPin('width', value.width)}
        />
        <Label>Width</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.width)}
          type="range"
          onChange={changeValue('width')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.width}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.height}
          onClick={() => onPin('height', value.height)}
        />
        <Label>Height</Label>
        <Input
          min={0}
          max={100}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.height)}
          type="range"
          onChange={changeValue('height')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.height}
        />
      </Flex>
      <H5 fontSize={0} mb={2}>
        Padding
      </H5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.paddingTop}
          onClick={() => onPin('paddingTop', value.paddingTop)}
        />
        <Label>Top</Label>
        <Input
          min={0}
          max={1024}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingTop)}
          type="range"
          onChange={changeValue('paddingTop')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.paddingTop}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.paddingBottom}
          onClick={() => onPin('paddingBottom', value.paddingBottom)}
        />
        <Label>Bottom</Label>
        <Input
          min={0}
          max={1024}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingBottom)}
          type="range"
          onChange={changeValue('paddingBottom')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.paddingBottom}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.paddingLeft}
          onClick={() => onPin('paddingLeft', value.paddingLeft)}
        />
        <Label>Left</Label>
        <Input
          min={0}
          max={1024}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingLeft)}
          type="range"
          onChange={changeValue('paddingLeft')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.paddingLeft}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins.paddingRight}
          onClick={() => onPin('paddingRight', value.paddingRight)}
        />
        <Label>Right</Label>
        <Input
          min={0}
          max={1024}
          steps={1}
          width={1}
          mr={2}
          value={Number(value.paddingRight)}
          type="range"
          onChange={changeValue('paddingRight')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value.paddingRight}
        />
      </Flex>
      <H5 mb={2} fontSize={0}>
        Border Radius
      </H5>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.topRightRadius}
            onClick={() => onPin('topRightRadius', value.topRightRadius)}
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌜
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.topRightRadius)}
            type="range"
            onChange={changeValue('topRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.topRightRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.mirrorTopRightRadius}
            onClick={() =>
              onPin('mirrorTopRightRadius', value.mirrorTopRightRadius)
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.mirrorTopRightRadius)}
            type="range"
            onChange={changeValue('mirrorTopRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.mirrorTopRightRadius}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.topLeftRadius}
            onClick={() => onPin('topLeftRadius', value.topLeftRadius)}
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌝
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.topLeftRadius)}
            type="range"
            onChange={changeValue('topLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.topLeftRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.mirrorTopLeftRadius}
            onClick={() =>
              onPin('mirrorTopLeftRadius', value.mirrorTopLeftRadius)
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.mirrorTopLeftRadius)}
            type="range"
            onChange={changeValue('mirrorTopLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.mirrorTopLeftRadius}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.bottomLeftRadius}
            onClick={() => onPin('bottomLeftRadius', value.bottomLeftRadius)}
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌞
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.bottomLeftRadius)}
            type="range"
            onChange={changeValue('bottomLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.bottomLeftRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.mirrorBottomLeftRadius}
            onClick={() =>
              onPin('mirrorBottomLeftRadius', value.mirrorBottomLeftRadius)
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.mirrorBottomLeftRadius)}
            type="range"
            onChange={changeValue('mirrorBottomLeftRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.mirrorBottomLeftRadius}
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pr={2}>
          <Lock
            bg="transparent"
            active={pins.bottomRightRadius}
            onClick={() => onPin('bottomRightRadius', value.bottomRightRadius)}
          />
          <Label
            lineHeight={1.0}
            fontSize={2}
            fontWeight={900}
            width={8}
            minWidth={8}
            mr={1}
          >
            ⌟
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.bottomRightRadius)}
            type="range"
            onChange={changeValue('bottomRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.bottomRightRadius}
          />
        </Flex>
        <Flex py={1} alignItems="center" width={1 / 2} pl={3}>
          <Lock
            bg="transparent"
            active={pins.mirrorBottomRightRadius}
            onClick={() =>
              onPin('mirrorBottomRightRadius', value.mirrorBottomRightRadius)
            }
          />
          <Label
            fontSize={0}
            fontWeight={700}
            lineHeight={1.0}
            minWidth={8}
            width={8}
            mr={1}
          >
            +
          </Label>
          <Input
            min={0}
            max={100}
            steps={1}
            width={1}
            mr={0}
            value={Number(value.mirrorBottomRightRadius)}
            type="range"
            onChange={changeValue('mirrorBottomRightRadius')}
          />
          <Span
            width="48px"
            textAlign="right"
            ml="auto"
            fontSize={0}
            children={value.mirrorBottomRightRadius}
          />
        </Flex>
      </Flex>
      <SectionBorder my={4} />
      <H5 mb={1}>Css</H5>
      <Textarea
        bg="transparent"
        height={64}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={''}
      />
      <H5 mb={1}>Js</H5>
      <Textarea
        bg="transparent"
        height={128}
        width={1}
        border="1px solid rgba(0,0,0,.25)"
        p={2}
        readOnly
        value={
          "import styled from '@emotion/styled'\n\n" +
          "const Avatar = styled('div')({\n" +
          //`  backgroundImage: '${randomAvatar.toBackgroundImage(value)}'` +
          `  backgroundImage: ''` +
          '\n})\n'
        }
      />
    </>
  )
}

export default ({ initialDiv, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('divVotes'))
  } catch (e) {}

  const [div, setDiv] = useState(initialDiv || randomDiv())
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const divWithPins = { ...div, ...pins }
  const fullDiv = {
    ...divWithPins,
    backgroundColor: 'red' //gradientWithPins.startColor,
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('divs')
      .collection('votes')
      .add({
        up: true,
        data: fullDiv,
        metadata: { pins }
      })

    localStorage.setItem(
      'divVotes',
      JSON.stringify(
        [
          {
            id
            //backgroundColor: fullGradient.backgroundColor,
            //backgroundImage: fullGradient.backgroundImage
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setDiv(randomDiv())
  }
  const skip = () => {
    setDiv(randomDiv())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = div => {
    setDiv(div)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main div={fullDiv} />
        )
      }
      sidebar={
        <Sidebar
          value={fullDiv}
          pins={pins}
          onChange={(key, value) => {
            setDiv({
              ...div,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            css={{
              color: 'black',
              textAlign: 'center',
              padding: 20,
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            <Link
              to="/div/votes"
              css={{
                color: 'black',
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                textDecoration: 'none'
              }}
            >
              <BookOpen size={20} />
              <span css={{ paddingLeft: 10 }}>Previous likes</span>
            </Link>
          </div>
          <Flex>
            <VoteButton
              disabled={gridView}
              css={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  opacity: 0.4
                }
              }}
              onClick={upvote}
            >
              <Heart size={20} />
              <Hidden>Save</Hidden>
            </VoteButton>
            <VoteButton onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton onClick={toggleGridView}>
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
