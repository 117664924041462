const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')
const randomBoxShadow = require('./box-shadow')

const textAlign = sample(['center', 'right', 'justify', 'left'])

const textDecorationStyle = sample([
  'solid',
  'double',
  'dotted',
  'dashed',
  'wavy'
])

const textDecorationLine = sample([
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'underline',
  'overline',
  'line-through',
  'blink'
])

const backgroundImageRepeat = sample([
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'no-repeat',
  'repeat',
  'repeat-x',
  'repeat-y',
  'space'
])

const backgroundSize = sample(['cover', 'contain'])

const backgroundPositionX = sample(['left', 'center', 'right', '25%', '75%'])

const backgroundPositionY = sample(['top', 'center', 'bottom', '25%', '75%'])

const blendModes = [
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'normal',
  'multiply',
  'screen',
  'overlay',
  'darken',
  'lighten',
  'color-dodge',
  'color-burn',
  'hard-light',
  'soft-light',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity'
]

const blendMode = sample(blendModes)

/*
 
  size: [width, height]

  outer borderRadius 
        -right
        -left
        -bottom
        -top

  inner borderRadius 

  outer border style
  outer border width
  outer border color

  inner border style
  inner border width
  inner border color

  wrapper bg
  wrapper padding
  wrapper boxShadow

  avatarImg
  
  

*/

const displays = [
  'block',
  'inline',
  'inline-block',
  'flex',
  'inline-flex',
  'grid',
  'table'
]
const display = sample(displays)

const borderStyles = [
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset'
]

const images = [
  'https://mrmrs.cc/photos/u/001.jpg',
  'https://mrmrs.cc/photos/u/002.jpg',
  'https://mrmrs.cc/photos/u/003.jpg',
  'https://mrmrs.cc/photos/u/004.jpg',
  'https://mrmrs.cc/photos/u/005.jpg',
  'https://mrmrs.cc/photos/u/006.jpg',
  'https://mrmrs.cc/photos/u/007.jpg',
  'https://mrmrs.cc/photos/u/008.jpg',
  'https://mrmrs.cc/photos/u/009.jpg',
  'https://mrmrs.cc/photos/u/010.jpg',
  'https://mrmrs.cc/photos/u/011.jpg',
  'https://mrmrs.cc/photos/u/012.jpg',
  'https://mrmrs.cc/photos/u/013.jpg',
  'https://mrmrs.cc/photos/u/014.jpg',
  'https://mrmrs.cc/photos/u/015.jpg',
  'https://mrmrs.cc/photos/u/016.jpg',
  'https://mrmrs.cc/photos/u/016.jpg',
  'https://mrmrs.cc/photos/u/017.jpg',
  'https://mrmrs.cc/photos/u/018.jpg',
  'https://mrmrs.cc/photos/u/019.jpg',
  'https://mrmrs.cc/photos/u/021.jpg',
  'https://mrmrs.cc/photos/u/022.jpg',
  'https://mrmrs.cc/photos/u/023.jpg'
]

const generateDiv = color => {
  const shadowBool = randomInt(0, 1)
  var boxShadow = 'none'
  if (shadowBool > 0) {
    boxShadow = randomBoxShadow()
  }

  color = color || randomHex()

  const colorScale = chroma
    .scale(['black', color, 'white'])
    .mode('lab')
    .correctLightness()
    .colors(16)

  const outerRadius = randomInt(0, 100)

  const radiusBool = randomInt(0, 10)

  const backgroundImage = sample(images)
  let outerRadiusMax = outerRadius
  let outerRadiusMin = outerRadius
  let innerRadiusMax = outerRadius
  let innerRadiusMin = outerRadius

  if (radiusBool > 5) {
    outerRadiusMax = randomInt(60, 100)
    outerRadiusMin = randomInt(10, 60)
    innerRadiusMax = randomInt(50, 100)
    innerRadiusMin = randomInt(10, 60)
  }

  const borderWidth = randomInt(0, 8)
  const outerBorderWidth = randomInt(0, 6)

  const size = randomInt(32, 80)
  const padding = randomInt(0, 8)

  const borderStyle = sample(borderStyles)

  const boxSizing = sample(['border-box', 'content-box'])

  const parentBgInt = randomInt(0, 10)
  const borderColorInt = randomInt(0, 100)

  let parentBg = ''
  if (parentBgInt < 3) {
    parentBg = 'black'
  } else if (parentBgInt < 8) {
    parentBg = colorScale[randomInt(1, 15)]
  } else {
    parentBg = 'white'
  }

  let borderTopColor = ''
  let borderBottomColor = ''
  let borderRightColor = ''
  let borderLeftColor = ''

  if (borderColorInt < 95) {
    const colorScaleInt = randomInt(0, 15)
    borderTopColor = colorScale[colorScaleInt]
    borderBottomColor = colorScale[colorScaleInt]
    borderRightColor = colorScale[colorScaleInt]
    borderLeftColor = colorScale[colorScaleInt]
  } else {
    borderTopColor = colorScale[randomInt(0, 15)]
    borderBottomColor = colorScale[randomInt(0, 15)]
    borderRightColor = colorScale[randomInt(0, 15)]
    borderLeftColor = colorScale[randomInt(0, 15)]
  }

  const div = {
    boxSizing: boxSizing,
    display: display,
    alignItems: 'center',
    parentBg: parentBg,
    color: colorScale[2], // set color for wrapper border
    outerBorderStyle: borderStyle,
    bg: colorScale[10], // set background of wrapper color
    //startColorHinting,
    //endColor: '#' + endColor,
    //endColorHinting,
    spread: 80,
    height: size,
    width: size,
    borderWidth: borderWidth,
    outerBorderWidth: borderWidth > 0 ? 0 : outerBorderWidth,
    padding: padding,
    paddingRight: padding,
    paddingLeft: padding,
    paddingTop: padding,
    paddingBottom: padding,
    topRightRadius: outerRadiusMin,
    topLeftRadius: outerRadiusMax,
    bottomRightRadius: outerRadiusMax,
    bottomLeftRadius: outerRadiusMin,
    mirrorTopRightRadius: outerRadiusMax,
    mirrorTopLeftRadius: outerRadiusMin,
    mirrorBottomRightRadius: outerRadiusMin,
    mirrorBottomLeftRadius: outerRadiusMax,
    boxShadow: boxShadow,
    borderTopWidth: '',
    borderTopStyle: '',
    borderTopColor: borderTopColor,
    borderBottomWidth: '',
    borderBottomStyle: '',
    borderBottomColor: borderBottomColor,
    borderLeftWidth: '',
    borderLeftStyle: '',
    borderLeftColor: borderLeftColor,
    borderRightWidth: '',
    borderRightStyle: '',
    borderRightColor: borderRightColor,
    borderImageSource: sample(images),
    borderImageSlice: randomInt(24, 128),
    borderImageWidth: randomInt(24, 64),
    borderImageOutset: randomInt(10, 48),
    borderImageRepeat: sample([
      'stretch',
      'repeat',
      'round',
      'space',
      'round stretch'
    ]),
    boxShadowSpread: 2,
    boxShadowBlur: 8,
    boxShadowColor: 'rgba(0,0,0,.15)',
    boxShadowOffsetX: 2,
    boxShadowOffsetY: 2,
    boxShadowInset: sample([false, true]),
    outlineStyle: '',
    outlineWidth: '',
    outlineColor: '',
    opacity: 100,
    textAlign: textAlign,
    textShadow: 'center',
    lineHeight: 100,
    letterSpacing: 0,
    textTransform: '',
    textShadowBlur: '',
    textShadowOffsetX: '',
    textShadowOffsetY: '',
    textShadowColor: '#000',
    fontStyle: '',
    whiteSpace: '',
    backgroundImage: backgroundImage,
    backgroundImageRepeat: backgroundImageRepeat,
    backgroundImageY: backgroundPositionY,
    backgroundImageX: backgroundPositionX,
    backgroundSize: backgroundSize,
    animationDelay: '',
    animationDirection: '',
    animationDuration: '',
    animationFillMode: '',
    animationIterationCount: '',
    animationName: '',
    animationPlayState: '',
    animationTimingFunction: '',
    maskClip: '',
    maskComposite: '',
    maskImage: '',
    maskMode: '',
    maskOrigin: '',
    maskRepeat: '',
    maskSize: '',
    maskType: '',
    mixBlendMode: blendMode,
    bgMixBlendMode: blendMode,
    blur: 0
  }

  return {
    ...div
  }
}

module.exports = generateDiv
